<template>
  <div>
  <v-toolbar
    flat
    style="padding: 5px 10px 0 10px;"
  >
    <v-toolbar-title v-if="title || icon"><v-icon v-if="icon" x-large>{{ icon }}</v-icon> {{ title }}</v-toolbar-title>
    <v-spacer />
    <small v-if="totalDuration" style="margin-left: 10px;">
      <b style="color:gray;">{{$t('common.availability.totalDuration', locale)}}</b> <b style="font-size:1rem;">{{convertTime(totalDuration)}}</b>
    </small>
  </v-toolbar>
  <v-toolbar
    flat
    style="padding-top: 5px;"
  >
    <!--v-divider
      v-if="title"
      class="mx-4"
      inset
      vertical
    /-->
    <v-tooltip bottom
      v-if="onGenerateMediaFeed"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          @click="onGenerateMediaFeed"
          color="primary"
          style="margin-left: 10px"
        >
          <v-icon>mdi-file-refresh-outline</v-icon>
        </v-btn>
      </template>
      <span>Generar MediaFeed</span>
    </v-tooltip>
    <play-loop
      v-if="playLoopConfig"
      :playLoopConfig="playLoopConfig"
      :locale="locale"
      style="margin-left: 10px;"
    />
    <!--small v-if="totalDuration" style="margin-left: 10px;">
      <b>
        | {{$t('common.availability.totalDuration', locale)}} {{convertTime(totalDuration)}}
      </b>
</small-->
    <v-spacer />

    <v-select
      v-if="onChangeVisibilityFilter"
      v-model="visibilityFilter"
      itemValue="id"
      itemText="name"
      dense
      label="Visibilidad"
      hide-details
      :items="[{
        id: 0,
        name: 'Visibles'
      },
      {
        id: 1,
        name: 'Ocultos'
      }]"
      :menu-props="{ maxWidth: '400', maxHeight: '400' }"
      multiple
      @change="() => { onChangeVisibilityFilter(visibilityFilter)}"
      outlined
    />
    <search v-if="onSearch" :onSearch="onSearch" label="Buscar" style="margin-left:10px;" />
    <v-btn
      v-if="onAdd && !hideAdd"
      color="primary"
      dark
      class="mb-2"
      @click="onAdd(null)"
      fab
      elevation="0"
      small
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-toolbar>
  </div>
</template>
<script>
  import utils from '@/services/utils'
  import Search from '@/components/Search'
  import PlayLoop from '@/components/customTable/playLoop/Index'
  export default{
    components: {
      Search,
      PlayLoop,
    },
    props: {
      icon: {
        type: String,
        default: null
      },
      title: {
        type: String,
        default: null
      },
      onAdd: {
        type: Function,
        default: null
      },
      onGenerateMediaFeed: {
        type: Function,
        default: null,
      },
      onSearch: {
        type: Function,
        default: null
      },
      hideAdd: {
        type: Boolean,
        default: false,
      },
      totalDuration: {
        type: Number,
        default: null,
      },
      locale: {
        type: String,
        default: 'es',
      },
      onChangeVisibilityFilter: {
        type: Function,
        default: null,
      },
      playLoopConfig: {
        type: Object,
        default: null,
      },
    },
    data: () => ({
      visibilityFilter: [0]
    }),
    methods: {
      convertTime (v) {
        if (v === null) return ''
        return utils.convertTime(v).split(' ').join('')
      },
    },
  }
</script>


