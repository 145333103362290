var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticStyle:{"padding":"5px 10px 0 10px"},attrs:{"flat":""}},[(_vm.title || _vm.icon)?_c('v-toolbar-title',[(_vm.icon)?_c('v-icon',{attrs:{"x-large":""}},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.title))],1):_vm._e(),_c('v-spacer'),(_vm.totalDuration)?_c('small',{staticStyle:{"margin-left":"10px"}},[_c('b',{staticStyle:{"color":"gray"}},[_vm._v(_vm._s(_vm.$t('common.availability.totalDuration', _vm.locale)))]),_vm._v(" "),_c('b',{staticStyle:{"font-size":"1rem"}},[_vm._v(_vm._s(_vm.convertTime(_vm.totalDuration)))])]):_vm._e()],1),_c('v-toolbar',{staticStyle:{"padding-top":"5px"},attrs:{"flat":""}},[(_vm.onGenerateMediaFeed)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-left":"10px"},attrs:{"color":"primary"},on:{"click":_vm.onGenerateMediaFeed}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-refresh-outline")])],1)]}}],null,false,636299689)},[_c('span',[_vm._v("Generar MediaFeed")])]):_vm._e(),(_vm.playLoopConfig)?_c('play-loop',{staticStyle:{"margin-left":"10px"},attrs:{"playLoopConfig":_vm.playLoopConfig,"locale":_vm.locale}}):_vm._e(),_c('v-spacer'),(_vm.onChangeVisibilityFilter)?_c('v-select',{attrs:{"itemValue":"id","itemText":"name","dense":"","label":"Visibilidad","hide-details":"","items":[{
        id: 0,
        name: 'Visibles'
      },
      {
        id: 1,
        name: 'Ocultos'
      }],"menu-props":{ maxWidth: '400', maxHeight: '400' },"multiple":"","outlined":""},on:{"change":function () { _vm.onChangeVisibilityFilter(_vm.visibilityFilter)}},model:{value:(_vm.visibilityFilter),callback:function ($$v) {_vm.visibilityFilter=$$v},expression:"visibilityFilter"}}):_vm._e(),(_vm.onSearch)?_c('search',{staticStyle:{"margin-left":"10px"},attrs:{"onSearch":_vm.onSearch,"label":"Buscar"}}):_vm._e(),(_vm.onAdd && !_vm.hideAdd)?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","fab":"","elevation":"0","small":""},on:{"click":function($event){return _vm.onAdd(null)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }