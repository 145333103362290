<template>
  <weekly-menu
    v-if="contentTypeData && contentTypeData.Type === 'weeklyMenu'"
    :contentTypeData="contentTypeData"
  />
  <event
    v-else-if="contentTypeData && contentTypeData.Type === 'event'"
    :key="contentTypeID"
    :contentTypeID="contentTypeID"
    :workspaceID="contentTypeData.WorkspaceID"
    :locale="locale"
  />
  <activity-barcelo
    v-else-if="contentTypeData && contentTypeData.Type === 'activityBarcelo'"
    :contentTypeDataID="contentTypeID"
    :contentTypeData="contentTypeData ? JSON.parse(contentTypeData.ActivityBarceloConfig) : null"
    :workspaceID="contentTypeData.WorkspaceID"
    :locale="locale"
  />
  <!--meeting-room
    v-else-if="contentTypeData && contentTypeData.Type === 'meetingRoom'"
    :contentTypeData="contentTypeData"
  /-->
  <v-layout wrap v-else-if="contentTypeData">
    <v-flex xs8 
      style="padding:20px 10px 20px 20px;height:calc(100vh - 65px);overflow-y: auto;overflow-x: hidden;scrollbar-width: none !important;"
    >
      <v-card elevation="0">
        <v-card-text style="padding:0px;">
          <list-content
            v-if="headers && contentTypeData"
            :id="contentTypeData.ID" 
            :onGenerateMediaFeed="handleGenerateMediaFeed"
            :onSearch="handleSearch"
            :onEditItem="handleEditItem"
            :onCloneItem="handleCloneItem"
            :onDeleteItem="handleDeleteItem"
            :onSort="handleSort"
            :icon="contentTypeData.icon"
            :title="contentTypeData.Name"
            :headers="headers"
            :data="data"
            :loading="loading"
            :locale="locale"
            :type="typeList"
            :contentTypeData="contentTypeData"
            :itemsPerPage="itemsPerPage"
            :totalDuration="totalDuration"
            :onChangeVisibilityFilter="['media', 'message', 'audio', 'htmlToImage'].indexOf(contentTypeData.Type) >= 0 ? handleChangeVisibilityFilter : null"
            :playLoopConfig="playLoopConfig"
            :prevHeight="80 * contentTypeData.Height / contentTypeData.Width"
            :onChangeMultiSelect="['media', 'message', 'audio', 'htmlToImage'].indexOf(contentTypeData.Type) >= 0 ? handleChangeMultiSelect : null"

            :clearMultiSelect="clearMultiSelect"
          />
          <pagination
            :count="count"
            :onGetData="handleGetData"
            :startIndex="startIndex"
            :itemsPerPage="itemsPerPage"
            :locale="locale"
            :onChangeItemsPerPage="handleChangeItemsPerPage"

            :multiSelectedID="multiSelectedID"
            :onDeleteItem="handleDeleteMultiID"
            :onHideItem="handleHideMultiID"
            :onShowItem="handleShowMultiID"
          />
        </v-card-text>
      </v-card>
      <!--availability-legend :locale="locale" /-->
    </v-flex>
    <v-flex xs4 style="padding:20px 20px 20px 10px;"
    >
      <v-card
        style="height:calc(100vh - 104px);overflow-y: auto;overflow-x: hidden;scrollbar-width: thin !important;"
        elevation="0"
      >
        <v-card-text>
          <v-btn
            v-if="!showForm"
            @click="handleAddContent"
            color="primary"
            block
            dark
          >
            {{ $t('common.add', locale) }}
          </v-btn>
          <availability-legend 
            v-if="!showForm"
            :isVertical="true"
            :locale="locale" 
          />
          <message-form
            v-if="contentTypeData.Type === 'message' && showForm"
            :isClone="isClone"
            :editedID="editedID"
            :contentTypeDataID="contentTypeData.ID"
            :title="contentTypeData.Name"
            :icon="contentTypeData.icon"
            :onClose="handleCloseForm"
            :onDelete="handleDeleteItem"
          />
          <media-form
            v-else-if="contentTypeData.Type === 'media' && showForm"
            :isClone="isClone"
            :editedID="editedID"
            :contentTypeData="contentTypeData"
            :allowedTypes="contentTypeData.AllowedTypes"
            :title="contentTypeData.Name"
            :icon="contentTypeData.icon"
            :onClose="handleCloseForm"
            :onDelete="handleDeleteItem"
          />
          <audio-form
            v-else-if="contentTypeData.Type === 'audio' && showForm"
            :editedID="editedID"
            :contentTypeData="contentTypeData"
            :title="contentTypeData.Name"
            :icon="contentTypeData.icon"
            :onClose="handleCloseForm"
            :onDelete="handleDeleteItem"
          />
          <meeting-room-form
            v-else-if="contentTypeData.Type === 'meetingRoom' && showForm"
            :editedID="editedID"
            :contentTypeData="contentTypeData"
            :contentTypeDataID="contentTypeData.ID"
            :title="contentTypeData.Name"
            :icon="contentTypeData.icon"
            :onClose="handleCloseForm"
            :onDelete="handleDeleteItem"
          />
          <html-to-image-form
            v-if="contentTypeData.Type === 'htmlToImage' && showForm"
            :editedID="editedID"
            :contentTypeDataID="contentTypeData.ID"
            :title="contentTypeData.Name"
            :icon="contentTypeData.icon"
            :onClose="handleCloseForm"
            :onDelete="handleDeleteItem"
          />
          <!--file-form
            v-else-if="showFileForm" 
            :data="editedItem"
            :onClose="handleCloseForms"
            :onDelete="handleDeleteItem"
          /-->
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
  const router = {
    message: 'messages',
    htmlToImage: 'html-to-images',
    media: 'media',
    audio: 'audios',
    meetingRoom: 'meeting-room',
  }
  const headers = {
    message: [
      { text: '', sortable: false, },
      { text: '', sortable: false, },
      { text: 'content.name', value: 'Name',  sortable: false, },
      { text: 'content.message', value: 'Message',  sortable: false, },
      { text: '', sortable: false, },
      { text: 'content.schedule',  sortable: false },
      { text: '',  sortable: false },
    ],
    media: [
      { text: '', sortable: false, },
      { text: '', sortable: false, },
      { text: 'content.media', value: 'MediaURL',  sortable: false, align: 'center', },
      { text: 'content.name', value: 'Name',  sortable: false, },
      { text: 'content.time', value: 'Time',  sortable: false, align: 'center', },
      { text: '', sortable: false, },
      { text: 'content.schedule',  sortable: false },
      { text: '',  sortable: false },
    ],
    audio: [
      { text: '', sortable: false, },
      { text: '', sortable: false, },
      { text: 'content.name', value: 'Name',  sortable: false, },
      { text: 'content.sequence', value: 'Sequence',  sortable: false, align: 'center', },
      { text: 'content.schedule',  sortable: false },
      { text: '',  sortable: false },
    ],
    meetingRoom: [
      { text: '', sortable: false, },
      { text: 'content.media', value: 'MediaURL',  sortable: false, align: 'center', },
      { text: 'content.name', value: 'Name',  sortable: false, },
      { text: 'content.time', value: 'Time',  sortable: false, align: 'center', },
      { text: 'content.schedule',  sortable: false },
      { text: '',  sortable: false },
    ],
    htmlToImage: [
      { text: '', sortable: false, },
      { text: '', sortable: false, },
      { text: 'content.name', value: 'Name',  sortable: false, },
      { text: 'content.url', value: 'URL',  sortable: false, },
      { text: 'content.time', value: 'Time',  sortable: false, align: 'center', },
      { text: 'content.schedule',  sortable: false },
      { text: '',  sortable: false },
    ],
  }

  import { mapState } from 'vuex'
  import api from '@/services/api'
  import Pagination from '@/components/customTable/Pagination'
  import ListContent from './listContent/Index'
  import AvailabilityLegend from './listContent/AvailabilityLegend'
  import MessageForm from './MessageForm'
  import MediaForm from './mediaForm/Index'
  import AudioForm from './AudioForm'
  import WeeklyMenu from './weeklyMenu/Index'
  import ActivityBarcelo from './activityBarcelo/Index'
  import Event from './event/Index'
  import MeetingRoomForm from './meetingRoom/Index'
  import HtmlToImageForm from './HtmlToImageForm'
  export default{
    components: {
      ListContent,
      MessageForm,
      MediaForm,
      AudioForm,
      Pagination,
      WeeklyMenu,
      ActivityBarcelo,
      MeetingRoomForm,
      AvailabilityLegend,
      HtmlToImageForm,
      Event,
    },
    data() {
      return {
        loading: false,
        headers: null,
        contentTypeData: null,
        isClone: false,
        editedID: null,
        showForm: false,
        search: '',
        startIndex: 0,
        itemsPerPage: 10,
        data: [],
        count: null,
        typeList: null,
        totalDuration: null,
        visibilityFilter: [0],

        multiSelectedID: [],
      }
    },
    computed: {
      ...mapState('app',['locale']),
      contentTypeID () {
        return this.$route.params.id
      },
      pathVersion () {
        return ['meetingRoom', 'htmlToImage'].indexOf(this.contentTypeData.Type) >= 0 ? 'v1/' : ''
      },
      baseAPIURL () {
        return process.env.VUE_APP_ENV === 'development' ? 'http://localhost:3900' : 'https://cm-dviewcontent-api.dviewcontent.com'
      },
      playLoopConfig () {
        if (this.contentTypeData.Type !== 'media') return
        return {
          width: this.contentTypeData.Width,
          height: this.contentTypeData.Height,
          //url: 'https://cm-dviewcontent-api.dviewcontent.com/assets/6bc3d12d-b5a3-43a1-bada-58cb1fad8b09/ac181b77-bf19-4a60-ab8e-b2c28d2b1d05/rss.xml',
          url: `${this.baseAPIURL}/assets/${this.contentTypeData.WorkspaceID}/${this.contentTypeData.ID}/rss.xml`.concat(`?t=${new Date().toISOString()}`),
        }
      },
    },
    watch: {
      '$route.params.id'() {
        this.handleGetContentType()
      }
    },
    mounted() {
      this.handleGetContentType()
    },
    methods: {
      handleGetTotalDuration () {
        if (!this.contentTypeID) {
          this.totalDuration = null
          return
        }
        if (['message', 'audio' ,'meetingRoom'].indexOf(this.contentTypeData.Type) >= 0) {
          this.totalDuration = null
          return
        }
        api.put('dviewcontent', `${this.pathVersion}private/${router[this.contentTypeData.Type]}/${this.contentTypeID}/total-duration`, this.startIndex, this.itemsPerPage, this.search)
          .then(response => {
            this.totalDuration = response && response.data !== null ? response.data : null
          })
      },
      async handleGetContentType () {
        try {
          this.showForm = false
          this.loading = true
          let contentTypeData = await new Promise((resolve) => api.getItem ('dviewcontent', 'private/content-types/', this.contentTypeID)
            .then(response => {
              response.AllowedTypes = !response.AllowedTypes ? null : response.AllowedTypes.map(item => {
                let value = item
                let name = item
                if (value.length === 36) {
                  name = response.CustomContentTypes ? response.CustomContentTypes.filter(x => x.ID === name).map(item => item.Name).shift() : value
                }
                return {
                  name,
                  value,
                }
              })
              resolve(response)
            }))
          this.contentTypeData = contentTypeData

          this.typeList=router[this.contentTypeData.Type]
          if (headers[this.contentTypeData.Type]) {
            let headersFormated = headers[this.contentTypeData.Type]
            if (this.contentTypeData.Type === 'audio' && !this.contentTypeData.BySequence) {
              headersFormated = headersFormated.filter(x => x.value != 'Sequence')
            }
            this.headers = headersFormated.map(item => {
              if(item.text && item.text.indexOf('.') > 0) item.text = this.$t(item.text, this.locale)
              return item
            })
            this.handleGetData()
          } else {
            this.startIndex = 0
            this.count = 0
            this.headers = []
            this.data = []
          }
        } catch (error) {
          console.log(error)
        }
      },
      handleGetData (startIndex) {
        this.loading = true
        this.clearMultiSelect = new Date().toISOString()
        if (startIndex >= 0 ) this.startIndex = startIndex
        const url = `${this.pathVersion}private/${router[this.contentTypeData.Type]}/${this.contentTypeID}/`
        api.getAll ('dviewcontent', url, this.startIndex, this.itemsPerPage, this.search, this.visibilityFilter.join(','))
          .then(response => {
            if (response) {
              this.count = response.count
              this.data = response.data.map (item => {
                if (this.contentTypeData.Type === 'media') {
                  item.previewConfig = {
                    width: this.contentTypeData.Width,
                    height: this.contentTypeData.Height,
                    screenshot: item.Screenshot,
                    url: item.MediaURL,
                    type: item.Type,
                  }
                }
                return item
              })
            }
            this.loading = false
            this.handleGetTotalDuration()
          })
      },
      handleEditItem (id) {
        this.isClone = false
        this.editedID = id
        this.showForm = true
      },
      handleCloneItem (v) {
        this.isClone = true
        this.editedID = v
        this.showForm = true
      },
      handleDeleteItem (id) {
        api.deleteItem ('dviewcontent', `${this.pathVersion}private/${router[this.contentTypeData.Type]}/${this.contentTypeData.ID}/`, id)
          .then(() => {   
            this.handleCloseForm()
          })
      },
      handleAddContent () {
        this.isClone = false
        this.editedID = null
        this.showForm = true
      },
      handleGenerateMediaFeed () {
        api.getAll('dviewcontent', `private/cron/${this.contentTypeData.ID}`)
          .then(() => {
            this.$store.state.app.mainAlert = {
              type: 'info',
              icon: 'mdi-information',
              message: this.$t(`content.messages.generateMediaFeed`, this.locale),
            }
          })
      },
      handleSearch (v) {
        this.search = v
        this.handleGetData()
      },
      handleCloseForm () {
        this.isClone = false
        this.handleGetData()
        this.showForm = false
      },
      handleSort(v) {
        const body = []
        const maxValue = Math.max.apply(null, v.map((o) => { return o.ORD }))
        for (let i = 0; i < v.length; i++)
          body.push ({ ID: v[i].ID, ORD: maxValue - i})
         
        api.put ('dviewcontent', `${this.pathVersion}private/${router[this.contentTypeData.Type]}/${this.contentTypeData.ID}/sort`, body)          
      },
      handleChangeItemsPerPage (v) {
        if (v) {
          this.startIndex = 0
          this.itemsPerPage = v
          this.handleGetData()
        }
      },
      handleChangeVisibilityFilter (v) {
        this.visibilityFilter = v
        this.handleGetData()
      },
      handleChangeMultiSelect (v) {
        this.multiSelectedID = v
      },
      handleDeleteMultiID (v) {
        api.deleteItem ('dviewcontent', `${this.pathVersion}private/${router[this.contentTypeData.Type]}/${this.contentTypeData.ID}/`, v.concat('/multi'))
          .then(() => {   
            this.handleGetData()
          })
      },
      handleHideMultiID () {
        const body = {
          IDs: this.multiSelectedID
        }
        api.put ('dviewcontent', `${this.pathVersion}private/${router[this.contentTypeData.Type]}/${this.contentTypeData.ID}/multi-hide`, body)    
          .then (() => {
            this.handleGetData()
          })
      },
      handleShowMultiID () {
        const body = {
          IDs: this.multiSelectedID
        }
        api.put ('dviewcontent', `${this.pathVersion}private/${router[this.contentTypeData.Type]}/${this.contentTypeData.ID}/multi-show`, body)    
          .then (() => {
            this.handleGetData()
          })
      },
    }
  }
</script>

<style>
  ::-webkit-scrollbar {
    display: none;
  }
</style>
