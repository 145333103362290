<template>
  <v-data-table
    :headers="headers"
    :items="data"
    :loading="loading"
    :itemsPerPage="itemsPerPage"
    item-key="ID"
    class="dviewcontent-table"
    hide-default-footer
    :style="cssProps"
  >
    <template v-slot:top>
      <table-title 
        :onAdd="onAdd"
        :title="contentTypeData.Name"
        :icon="contentTypeData.icon"
        :onSearch="onSearch"
        :onGenerateMediaFeed="onGenerateMediaFeed"
        :totalDuration="totalDuration"
        :onChangeVisibilityFilter="onChangeVisibilityFilter"
        :playLoopConfig="playLoopConfig"

      />
    </template>
    <template v-slot:body="props">
      <!--draggable
        :list="props.items"
        tag="tbody"
        :draggable="'.itemDraggable'"
      -->
      <template-nodata
        v-if="!loading && data.length === 0"
        :colspan="headers.length"
        :locale="locale"
        :onAdd="onAdd"
      />
      <template-message
        v-else-if="props.items && contentTypeData.Type === 'message'"
        :contentID="id"
        :items="props.items"
        :onDeleteItem="onDeleteItem"
        :onEditItem="onEditItem"
        :onSort="onSort"
        :locale="locale"
        :onCloneItem="onCloneItem"
        :onChangeMultiSelect="onChangeMultiSelect"
        :clearMultiSelect="clearMultiSelect"
      />
      <template-html-to-image
        v-else-if="props.items && contentTypeData.Type === 'htmlToImage'"
        :contentID="id"
        :items="props.items"
        :onDeleteItem="onDeleteItem"
        :onEditItem="onEditItem"
        :onSort="onSort"
        :locale="locale"
        :onChangeMultiSelect="onChangeMultiSelect"
        :clearMultiSelect="clearMultiSelect"
      />
      <template-media
        v-else-if="props.items && contentTypeData.Type === 'media'"
        :contentID="id"
        :items="props.items"
        :onDeleteItem="onDeleteItem"
        :onEditItem="onEditItem"
        :onCloneItem="onCloneItem"
        :onSort="onSort"
        :locale="locale"
        :prevHeight="prevHeight"
        :onChangeMultiSelect="onChangeMultiSelect"
        :clearMultiSelect="clearMultiSelect"
      />
      <template-meeting-room
        v-else-if="props.items && contentTypeData.Type === 'meetingRoom'"
        :contentID="id"
        :items="props.items"
        :onDeleteItem="onDeleteItem"
        :onEditItem="onEditItem"
        :onSort="onSort"
        :locale="locale"
      />
      <template-audio
        v-else-if="props.items && contentTypeData.Type === 'audio'"
        :contentID="id"
        :items="props.items"
        :onDeleteItem="onDeleteItem"
        :onEditItem="onEditItem"
        :onSort="onSort"
        :locale="locale"
        :bySequence="contentTypeData.BySequence"
        :onChangeMultiSelect="onChangeMultiSelect"
        :clearMultiSelect="clearMultiSelect"
      />
        <!--tr
          v-for="(item, index) in props.items"
          :key="index"
          class="itemDraggable"
        >
          <td width="30px">
            <v-icon
              small
              class="page__grab-icon"
            >
              mdi-cursor-move
            </v-icon>
          </td>
          <td>
            {{ item.Name }}
          </td>
          <td>
            {{ item.Message }}
          </td>
          <td>
            {{ item.Time }}
          </td>
          <td style="text-align:right;">
            <v-icon
              v-if="onEditItem"
              small
              class="mr-2"
              @click="onEditItem(item.ID)"
            >
              mdi-pencil
            </v-icon>
            <delete-item
              v-if="onDeleteItem"
              :id="item.ID"
              :onDeleteItem="onDeleteItem"
              icon="mdi-delete"
              :locale="locale"
            />
          </td>
        </tr>
      </draggable-->
    </template>
  </v-data-table>
</template>
<script>
  //import draggable from 'vuedraggable'
  //import CustomTableTitle from '@/components/customTable/CustomTableTitle'
  import TableTitle from './TableTitle'
  
  //import DeleteItem from '@/components/DeleteItem'
  import TemplateNodata from './TemplateNodata'
  import TemplateMessage from './TemplateMessage'
  import TemplateHtmlToImage from './TemplateHtmlToImage'
  import TemplateMedia from './TemplateMedia'
  import TemplateMeetingRoom from './TemplateMeetingRoom'
  import TemplateAudio from './TemplateAudio'

  export default{
    components: {
      //draggable,
      //CustomTableTitle,
      TableTitle,
      //DeleteItem,
      TemplateNodata,
      TemplateMessage,
      TemplateMedia,
      TemplateAudio,
      TemplateHtmlToImage,
      TemplateMeetingRoom,
    },
    props: {
      id: {
        type: String,
        required: true
      },
      contentTypeData: {
        type: Object,
        default: null,
      },
      onSearch: {
        type: Function,
        default: null
      },
      onEditItem: {
        type: Function,
        default: null
      },
      onCloneItem: {
        type: Function,
        default: null
      },
      onDeleteItem: {
        type: Function,
        default: null
      },
      onAdd: {
        type: Function,
        default: null
      },
      onGenerateMediaFeed: {
        type: Function,
        default: null,
      },
      onSort: {
        type: Function,
        default: null,
      },
      headers: {
        type: Array,
        required: true
      },
      data: {
        type: Array,
        default: null
      },
      loading: {
        type: Boolean,
        default: false
      },
      locale: {
        type: String,
        required: true
      },
      itemsPerPage: {
        type: Number,
        default: 5,
      },
      totalDuration: {
        type: Number,
        default: null,
      },
      onChangeVisibilityFilter: {
        type: Function,
        default: null,
      },
      onChangeMultiSelect: {
        type: Function,
        default: null,
      },
      prevHeight: {
        type: Number,
        default: null,
      },
      playLoopConfig: {
        type: Object,
        default: null,
      },
      clearMultiSelect: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
      }
    },
    computed: {
      cssProps () {
        return {
          '--secondary-color': this.$vuetify.theme.themes.light.primary.substring(0, 8).concat('1A')
        }
      },
    },
  }
</script>

<style>
.dviewcontent-table .v-data-table-header {
  background: var(--secondary-color);
}
.dviewcontent-table .v-data-table-header th {
  border: 0.5px solid white !important;
}
.v-data-table__wrapper {
  border-radius: 10px 10px 0 0;
}
.dviewcontent-table .v-data-table-header th:first-child { 
  border-radius: 10px 0 0 0;
}
.dviewcontent-table .v-data-table-header th:last-child { 
  border-radius: 0 10px 0 0;
}

</style>
