import { render, staticRenderFns } from "./TemplateMedia.vue?vue&type=template&id=4b438938&scoped=true&"
import script from "./TemplateMedia.vue?vue&type=script&lang=js&"
export * from "./TemplateMedia.vue?vue&type=script&lang=js&"
import style0 from "./TemplateMedia.vue?vue&type=style&index=0&id=4b438938&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b438938",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCheckbox,VIcon,VTooltip})
